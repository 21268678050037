<template>
    <div class="item" v-if="item">
        <div class="icon-box text-success">
            <ion-icon name="trending-up-outline"></ion-icon>
        </div>
        <div class="in">
            <div>
                <strong>{{ item.coin.name }}</strong>
                <div class="text-small text-secondary">{{ item.balance }} {{ item.coin.symbol }}</div>
            </div>
            <div class="text-end">
                <strong>{{ parseFloat(item.exchange).toFixed(currentInfo.coin.decimals_show) }} {{ currentInfo.coin.symbol }}</strong>
                <div class="text-small">
                    <!-- <span class="badge badge-success">
                        <ion-icon name="arrow-up-outline"></ion-icon>
                        -1%
                    </span> -->
                    <!-- <span class="badge badge-success">
                        <ion-icon name="arrow-up-outline"></ion-icon>
                        Recibir
                    </span> -->
                    <router-link class="badge badge-danger" :to="{ name: 'crypto-send', query: { asset: item.address } }">
                        <ion-icon name="arrow-up-outline"></ion-icon>
                        Enviar
                    </router-link>
                    <span class="badge badge-info">
                        <ion-icon name="arrow-up-outline"></ion-icon>
                        Swap
                    </span>
                    <!-- <span class="badge badge-warning">
                        <ion-icon name="arrow-up-outline"></ion-icon>
                        Comprar
                    </span>
                    <span class="badge badge-primary">
                        <ion-icon name="arrow-up-outline"></ion-icon>
                        Vender
                    </span> -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    name: 'CryptoPortfolioListItem',
    props: [
        'item',
    ],
    computed: {
        ...mapGetters({ 'currentInfo': 'StateUser' }),
    }
}
</script>