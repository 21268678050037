<template>
  <div>
    <!-- <h1>Generador de Código QR para Wallet Ethereum</h1> -->
    <!-- <input v-model="address" placeholder="Introduce la dirección de wallet Ethereum" /> -->
    <!-- <button @click="generateQRCode">Generar Código QR</button> -->
    <div v-if="qrCodeDataUrl">
      <img :src="qrCodeDataUrl" alt="Código QR" />
    </div>
  </div>
</template>

<script>
import QRCode from 'qrcode';

export default {
    props: ['address'],
  data() {
    return {
    //   address: '',
      qrCodeDataUrl: null,
    };
  },
  mounted() {
    this.generateQRCode()
  },
  methods: {
    generateQRCode() {
      try {
        // this.qrCodeDataUrl = QRCode.toDataURL(this.address);
        QRCode.toDataURL(this.address)
        .then(a => {
            console.log('a', a)
            this.qrCodeDataUrl = a
        })
      } catch (error) {
        console.error('Error generando el código QR:', error);
      }
    },
  },
};
</script>

<style scoped>
input {
  margin-bottom: 10px;
  padding: 8px;
  width: 300px;
}
button {
  margin-bottom: 20px;
  padding: 8px;
  cursor: pointer;
}
img {
  margin-top: 20px;
}
</style>
