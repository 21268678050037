<template>
  <!-- Transactions -->
  <div class="section mt-4">
      <div class="section-heading">
          <h2 class="title">Transacciones Activos (Pendte definir iconos y tipos de transacciones)</h2>
          <router-link :to="{ name: 'crypto-transactions' }" v-if="records.length > 0" class="link">Ver todo</router-link>
      </div>
      <div class="card">
          <ul class="listview flush transparent no-line image-listview detailed-list mt-1 mb-1" v-if="assets !== null">

            <li v-if="records.length > 0" v-for="(tx, index) in records" :key="index">
              <crypto-transaction-list-item :tx="tx" />
            </li>
            <li v-else>
              <a href="#" class="item">
                <div class="icon-box bg-secondary">
                  <ion-icon name="home"></ion-icon>
                </div>
                <div class="in">
                  <div>
                    <strong>Sin transacciones</strong>
                    <div class="text-small text-secondary"></div>
                  </div>
                  <div class="text-end">
                    <strong></strong>
                    <div class="text-small"></div>
                  </div>
                </div>
              </a>
            </li>
          </ul>
      </div>
  </div>
</template>

<script>
import { ethers } from "ethers";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      records: [],
    };
  },
  computed: {
    ...mapGetters({ 'currentInfo': 'StateUser' }),
    assets() {
      let records = {}
      this.$root.assets.forEach(asset => {
          records[asset.address] = asset
          // records.push(asset.address, address)
      });
      return (JSON.stringify(records).length > 2) ? records : null
    },
  },
  mounted() {
    this.getTransactions();
  },
  methods: {
    userFromWallet(address) {
      return (address == this.currentInfo.wallet.address) ? 'Yo' : 'Otro usuario';
    },
    
    async getTransactions() {
      let self = this
      self.records = []
      console.log('getTransactions')
      try {
        let walletAddress = self.currentInfo.wallet.address ?? self.currentInfo.wallet
        console.log('walletAddress', walletAddress)
        const provider = new ethers.JsonRpcProvider(self.$root.provider.url);
        // Obtener el último bloque
        const latestBlockNumber = await provider.getBlockNumber();
        console.log('latestBlockNumber', latestBlockNumber)
        // Definir cuántos bloques atrás deseas explorar (en este caso, los últimos 10 bloques)
        const numberOfBlocksToCheck = 10;
        let foundTransactions = [];
        // Recorrer los bloques recientes
        for (let i = latestBlockNumber; i >= latestBlockNumber - numberOfBlocksToCheck; i--) {
          console.log('i', i)
          const block = await provider.getBlock(i);
          // console.log("Block:", block);
          // console.log("Transactions:", block.transactions);
          // ABI mínima para un token ERC-20 (solo necesita las firmas de las funciones)
          const erc20Abi = [
            "function transfer(address dst, uint wad)",
            "function transferFrom(address src, address dst, uint wad)",
            "function withdrawExternal(address dst, address asset, uint wad)",
            "function withdrawTo(uint wad, address dst)",
            "function withdraw(uint wad)",
            "function depositExternal(address dst, address asset, uint wad)",
            "function depositTo(address dst)",
            "function deposit()",
            "function swap(address src, address dst, uint256 amount)",
          ];
          // Crear una instancia de la interfaz usando el ABI
          const iface = new ethers.Interface(erc20Abi);
          block.transactions.forEach(async (txHash) => {
            let itemTx = {}
            let src = null
            let dst = null
            let wad = 0
            let amount = 0
            let tx = await provider.getTransaction(txHash)
            // console.log('txHash', txHash, 'tx', tx)

            // src = tx.from
            
            tx.functionSelector = tx.data.slice(0, 10); // El 0x + 8 caracteres = 10 caracteres
            tx.functionFragment = iface.getFunction(tx.functionSelector);
            if (tx.functionSelector == '0x' || tx.functionFragment == null) {} // Moneda/Funciones nativas
            else {
              tx.decodedData = iface.decodeFunctionData(tx.functionFragment.name, tx.data);
              console.log(`Función llamada: ${tx.functionFragment.name}`);
              if (tx.functionFragment.name === "transfer") {
                src = tx.from, dst = tx.decodedData.dst, wad = tx.decodedData.wad, amount = tx.decodedData.wad
              }
              else if (tx.functionFragment.name === "transferFrom") {
                src = tx.decodedData.src, dst = tx.decodedData.dst, wad = tx.decodedData.wad, amount = tx.decodedData.wad
              }
              else if (tx.functionFragment.name === "withdrawExternal" || tx.functionFragment.name === "depositExternal") {
                // tx.from = tx.decodedData.dst, 
                tx.to = tx.decodedData.asset, src = tx.decodedData.asset, dst = tx.decodedData.dst, wad = tx.decodedData.wad, amount = tx.decodedData.wad
                console.log('withdrawExternal:depositExternal', tx, tx.decodedData.dst, tx.decodedData.asset, tx.decodedData.wad)
              }
              else if (tx.functionFragment.name === "swap") {
                console.log('swap', tx, tx.decodedData.src, tx.decodedData.dst, tx.decodedData.amount)
                // tx.from = walletAddress;
                tx.to = tx.decodedData.src
                src = walletAddress, dst = tx.decodedData.dst, wad = tx.decodedData.amount, amount = tx.decodedData.amount
              }
              // else if (tx.functionFragment.name === "withdrawTo") {
              //   src = tx.from, dst = tx.decodedData.dst, wad = tx.decodedData.wad, amount = tx.decodedData.wad
              // } 0x904016D2b3508b7D80a119e664aC0E13061A345d
              // else if (tx.functionFragment.name === "depositTo") {
              //   src = tx.from, dst = tx.decodedData.dst, wad = tx.value, amount = tx.value
              // }
  
              if ((src && src.toLowerCase() === walletAddress.toLowerCase()) || (dst && dst.toLowerCase() === walletAddress.toLowerCase())) { // PARA TOKENS
                console.log("Anexando:", tx)
                tx.src = src, tx.dst = dst, tx.wad = wad, tx.amount = amount
                foundTransactions.push(tx);
              }
              
            }
          });

        }
        this.records = foundTransactions;
        if (this.records.length === 0) {
          console.log("No se encontraron transacciones para la dirección proporcionada.");
        }
      } catch (error) {
        console.error("Error al obtener las transacciones:", error);
      }
    }
  },
};
</script>