<template>
    <!-- Wallet -->
    <div class="section full gradientSection">
        <div class="in">
            <h5 class="title mb-2">Saldo actual</h5>
            <h1 class="total">$ {{ currentInfo.balance }} {{ currentInfo.coin.symbol }}</h1>
            <h4 class="caption">
                &nbsp;
                <!-- <span class="iconbox text-success">
                    <ion-icon name="trending-up-outline"></ion-icon>
                </span> -->
                Total: $ 0.053205 COP
            </h4>
            <div class="wallet-inline-button mt-5">
                <router-link :to="{ name: 'crypto-deposit' }" class="item">
                    <div class="iconbox">
                        <ion-icon name="arrow-down-outline"></ion-icon>
                    </div>
                    <strong>Recargar</strong>
                </router-link>
                <router-link :to="{ name: 'crypto-send' }" class="item">
                    <div class="iconbox">
                        <ion-icon name="arrow-forward-outline"></ion-icon>
                    </div>
                    <strong>Enviar</strong>
                </router-link>
                
                <router-link :to="{ name: 'me-code-qr' }" class="item">
                    <div class="iconbox">
                        <ion-icon name="qr-code"></ion-icon>
                    </div>
                    <strong>Mi QR</strong>
                </router-link>
                
                <a href="/exchange" class="item">
                    <div class="iconbox">
                        <ion-icon name="swap-vertical-outline"></ion-icon>
                    </div>
                    <strong>Intercambio</strong>
                </a>
                <a href="/withdraw" class="item">
                    <div class="iconbox">
                        <ion-icon name="arrow-up-outline"></ion-icon>
                    </div>
                    <strong>Retirar</strong>
                </a>
            </div>
        </div>
    </div>
    <!-- * Wallet -->
    <!-- <ModalDepositActionSheet />
    <ModalSendActionSheet /> -->
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  computed: {
    ...mapGetters({ 'currentInfo': 'StateUser' }),
  }
}
</script>