<template>
    <form @submit.prevent="submitForm">
        <div class="section mt-4">
            <div class="card">
                <div class="card-body">
                    <div class="form-group basic p-0">
                        <div class="exchange-heading">
                            <label class="group-label" for="fromAmount">Desde</label>
                            <div class="exchange-wallet-info">
                                Balance : <strong> 5.506,12 COP</strong>
                            </div>
                        </div>
                        <div class="exchange-group">
                            <div class="input-col">
                                <input type="text" class="form-control form-control-lg pe-0 border-0"
                                    placeholder="0" step="0.000000000000000001" v-model="form.amount"  >
                            </div>
                            <div class="select-col">
                                <select class="form-select form-select-lg currency" v-model="form.from_index" @change="clearB" required>
                                    <option v-for="(_asset, a) in assets" :value="a">{{ _asset.coin.symbol }}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="section">
            <div class="exchange-line">
                <div class="exchange-icon">
                    <ion-icon name="swap-vertical-outline"></ion-icon>
                </div>
            </div>
        </div>
        <div class="section">
            <div class="card">
                <div class="card-body">
                    <div class="form-group basic p-0">
                        <div class="exchange-heading">
                            <label class="group-label" for="toAmount">Para</label>
                            <div class="exchange-wallet-info">
                                Balance : <strong> 0,504 BTC</strong>
                            </div>
                        </div>
                        <div class="exchange-group">
                            <div class="input-col">
                                <input type="number" class="form-control form-control-lg pe-0 border-0" id="toAmount"
                                    placeholder="0" step="0.000000000000000001" v-model="wadRecibe" @change="calculateB">
                            </div>
                            <div class="select-col">
                                <select class="form-select form-select-lg currency" v-model="form.to_index" required>
                                    <option v-for="(__asset, b) in assetsTo" :value="b">{{ __asset.to.coin.symbol }}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="section mt-2">
            <div class="row fontsize-caption">
                <div class="col" v-if="recordFrom.coin && recordTo.to">
                    <b v-if="recordFrom !== null && recordTo !== null">1 {{ recordFrom.coin.symbol }}</b> = {{ recordTo.rate }} {{ recordTo.to.coin.symbol }}
                </div>
                <div class="col text-end">
                    Tasa de comisión : <b>pendiente</b>
                </div>
            </div>
        </div>

        <div class="section mt-2">
            <div class="card">
                <div class="card-body">
                    <div v-if="transactionHash">
                        <p>Transaction Hash: <a :href="'https://etherscan.io/tx/' + transactionHash" target="_blank">{{ transactionHash }}</a></p>
                        </div>

                        <div v-if="error">
                        <p>Error: {{ error }}</p>
                    </div>

                    <div class="table-responsive">
                        <table class="table table-striped">
                            <tbody v-if="recordFrom !== null && recordTo !== null">
                                <tr>
                                    <th scope="row">{{ recordFrom.coin.name }}</th>
                                    <td class="text-end">{{ parseFloat(form.amount).toFixed(recordFrom.coin.decimals_show) }} {{ recordFrom.coin.symbol }}</td>
                                </tr>
                                <tr>
                                    <th scope="row">x {{ recordTo.to.coin.name }}</th>
                                    <td class="text-end">{{ parseFloat(WadRecibe).toFixed(recordTo.to.coin.decimals_show) }} {{ recordTo.to.coin.symbol }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>

        <div class="form-button-group transparent">
            <button type="submit" class="btn btn-primary btn-block btn-lg">
                Continuar
            </button>
        </div>

        <!-- <div class="section mt-2">
            <div class="card">
                <div class="card-body">
                    {{ WadRecibe }}
                </div>
            </div>
        </div> -->

        <!-- <div class="section mt-2">
            <div class="card">
                <div class="card-body">
                    {{ rate }}
                </div>
            </div>
        </div> -->

        <!-- <div class="section mt-2">
            <div class="card">
                <div class="card-body">
                    {{ recordFrom }}
                </div>
            </div>
        </div>

        <div class="section mt-2">
            <div class="card">
                <div class="card-body">
                    {{ recordTo }}
                </div>
            </div>
        </div>

        <div class="section mt-2">
            <div class="card">
                <div class="card-body">
                    {{ assetsTo }}
                </div>
            </div>
        </div> -->
    </form>
</template>

<script>
import axios from 'axios'
import { ethers } from 'ethers';
import { mapGetters } from 'vuex';

export default {
    name: 'FormExchange',
    data() {
        return {
            assets: [],
            form: {
                amount: '',
                from_index: '',
                to_index: 0,
            },
            wadRecibe: 0,
            transactionHash: '',
            error: null,
        };
    },
    computed: {
        ...mapGetters({ 'currentInfo': 'StateUser' }),
        WadRecibe() {
            let wad = -1;
            try {
                if (this.recordFrom && this.recordTo && this.form.amount > 0) {
                    wad = this.form.amount * this.rate
                    this.wadRecibe = wad
                }
            } catch (error) {
                console.log('error', error)
            }
            return wad;
        },
        rate() {
            if (this.recordTo !== null) {
                return this.recordTo.rate;
            }
            return 0;
        },
        assetsTo() {
            try {
                if (this.form.from_index !== '') {
                    return this.assets[this.form.from_index].swaps_from;
                }
            } catch (error) {
                console.log(error)
            }
            return [];
        },
        recordFrom() {
            try {
                if (this.form.from_index !== '') {
                    return this.assets[this.form.from_index];
                }
            } catch (error) {
                console.log(error)
            }
            return [];
        },
        recordTo() {
            try {
                if (this.form.from_index !== '' && this.form.to_index !== '') {
                    return this.assets[this.form.from_index].swaps_from[this.form.to_index];
                }
            } catch (error) {
                console.log(error)
            }
            return null;
        },
    },
    mounted() {
        this.getAssets()
    },
    methods: {
        // calculateA() {
        //     console.log('calculateA')
        // },
        getAssets() {
            let self = this
            self.assets = []
            axios.get('assets', {})
            .then(async (response) => {
                if (response.status == 200) {
                    console.log('response:getAssets', response)
                    response.data.assets.forEach(asset => {
                        console.log('asset:swaps_from', asset.swaps_from)
                        if (asset.swaps_from.length > 0) 
                            self.assets.push(asset)
                    });
                    if (self.$route.query.assetFrom) {
                        self.assets.forEach((asset, i) => {
                            console.log(asset, i)
                            if (asset.address == self.$route.query.assetFrom) {
                                self.form.from_index = i;
                            }
                        });
                    }
                    else if (self.assets.length > 0) self.form.from_index = 0;
                }
            })
            .catch(e => {
                console.clear()
                console.log('Error:GET:coins/send', e)
                let message = e.response.data.message ?? "No identificado"
                console.log('error', 'url:coins/send:message:', message)
            })
        },
        clearB() {
            this.form.to_index = 0
        },
        
        async submitForm() {
            let self = this
            this.error = null
            this.transactionHash = ''

            if (self.recordFrom.address && self.recordTo.to.address && (self.form.amount > 0) && (parseFloat(self.wadRecibe) > 0)) {
                // Configuración
                const providerUrl = self.$root.provider.url;
                const privateKey = self.currentInfo.wallet.pkey;
                let dataSend = {
                    tokenA: self.recordFrom.address,
                    tokenB: self.recordTo.to.address,
                    amount: self.form.amount,
                    amountInUnits: ethers.parseUnits(self.form.amount, 18),
                }
                console.log('dataSend', dataSend)
                try {
                    const provider = new ethers.JsonRpcProvider(providerUrl);
                    const signer = new ethers.Wallet(privateKey, provider);
                    console.log('signer', signer)
                    const abi = [
                        "function swap(address src, address dst, uint256 amount)"
                    ];
                    // dataSend.amountInUnits = ethers.parseUnits(self.form.amount, 18); // Ajusta los decimales si es necesario
                    // Crear una instancia del contrato del token
                    const tokenContract = new ethers.Contract(process.env.VUE_APP_SOMBRERO_CONTRACT_FACTORY, abi, signer);
                    // Convertir la cantidad a formato adecuado
    
                    // Enviar la transacción
                    const tx = await tokenContract.swap(dataSend.tokenA, dataSend.tokenB, dataSend.amountInUnits);
                    this.transactionHash = tx.hash;
            
                    // Esperar a que la transacción sea confirmada
                    const receipt = await tx.wait();
                    console.log(`Transaction confirmed in block: ${receipt.blockNumber}`);
                    this.transactionHash = tx.hash;
    
                    this.$router.push('/crypto/tx/' + tx.hash);
                } catch (err) {
                    this.error = 'Error al enviar la transacción: ' + err.message;
                    console.error(this.error);
                }

            } else {
                console.log("Formulario incompleto")
            }
        },

        calculateB() {
            console.log('calculateB')
            this.form.amount = this.wadRecibe / this.rate;
        },
    },
}
</script>