<template>

    <!-- App Header -->
    <div class="appHeader no-border">
        <div class="left">
            <router-link :to="{ name: 'app-home' }" class="headerButton">
                <ion-icon name="chevron-back-outline"></ion-icon>
            </router-link>
        </div>
        <div class="pageTitle">
            Recibir
        </div>
        <div class="right">
            <a href="#" class="headerButton" data-bs-toggle="modal" data-bs-target="#DialogBasic">Ayuda</a>
        </div>
    </div>
    <!-- * App Header -->

    <!-- Dialog Basic -->
    <div class="modal fade dialogbox" id="DialogBasic" data-bs-backdrop="static" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Código QR</h5>
                </div>
                <div class="modal-body">
                    Use el codigo QR y/o la direccion de la billetera para las transacciones dentro de sombrero, para depositar desde otro proveedor consulte la seccion de recargas.
                </div>
                <div class="modal-footer">
                    <div class="btn-inline">
                        <a href="#" class="btn btn-text-primary" data-bs-dismiss="modal">Aceptar</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- * Dialog Basic -->

    <!-- App Capsule -->
    <div id="appCapsule">
        <div class="section">
            <div class="splash-page mt-5 mb-5">
                <div class="mb-3">
                    <!-- <img src="@/assets/img/sample/qr.png" alt="QR Code" class="imaged square w140"> -->
                     <QRCodeGenerator :address="currentInfo.wallet.address ?? '0x0'" />
                </div>
                <h2 class="mb-2">{{ currentInfo.wallet.address }}</h2>
                <p class="small">{{ currentInfo.wallet }}</p>
            </div>
        </div>

        <div class="fixed-bar">
            <div class="row">
                <div class="col-12">
                    <router-link :to="{ name: 'app-home' }" class="btn btn-lg btn-outline-secondary btn-block">Vision general</router-link>
                </div>
            </div>
        </div>
    </div>
    <!-- * App Capsule -->
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    computed: {
        ...mapGetters({ 'currentInfo': 'StateUser' }),
    },
}
</script>