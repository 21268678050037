<template>
    <AppHeader />
    <div id="appCapsule">
        <SectionWalletCard />
        <!-- <SectionStats /> -->
        <crypto-section-portfolio />
        <!-- <SectionWatchlist /> -->
        <crypto-section-transactions />
        <!-- <SectionTransactions /> -->
        <!-- <SectionMyCards /> -->
        <!-- <SectionSendMoney /> -->
        <!-- <SectionMonthlyBills />
        <SectionSavingGoals />
        <SectionLastestNews /> -->
        <br />
        <AppFooter />
    </div>
    <AppBottomMenu />
    <AppSidebar />
</template>

<script>
import SectionSendMoney from '@/components/SectionSendMoney.vue';
import ApexCharts from 'apexcharts'
export default {
    name: 'HomeView',
    created() {
    },
    mounted() {
        this.$root.runBase()
        
    },
    methods: {
    },
}
</script>