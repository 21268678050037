<template>
    
    <!-- App Sidebar -->
    <div class="modal fade panelbox panelbox-left" id="sidebarPanel" tabindex="-1" role="dialog" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-body p-0">
                    <!-- profile box -->
                    <div class="profileBox pt-2 pb-2">
                        <!-- <div class="image-wrapper">
                            <img src="@/assets/img/sample/avatar/avatar1.jpg" alt="image" class="imaged  w36">
                        </div> -->
                        <div class="in">
                            <strong>Username/FullName</strong>
                            <div class="text-muted">userID</div>
                        </div>
                        <a href="#" class="btn btn-link btn-icon sidebar-close" data-bs-dismiss="modal">
                            <ion-icon name="close-outline"></ion-icon>
                        </a>
                    </div>
                    <!-- * profile box -->
                    <!-- balance -->
                    <div class="sidebar-balance">
                        <div class="listview-title">Balance</div>
                        <div class="in">
                            <h3 class="amount">{{ currentInfo.balance }} {{ currentInfo.coin.symbol }}</h3>
                        </div>
                    </div>
                    <!-- * balance -->

                    <!-- action group -->
                    <div class="action-group">
                        <a href="/deposit" class="action-button">
                            <div class="in">
                                <div class="iconbox">
                                    <ion-icon name="add-outline"></ion-icon>
                                </div>
                                Depositar
                            </div>
                        </a>
                        <a href="/send" class="action-button">
                            <div class="in">
                                <div class="iconbox">
                                    <ion-icon name="arrow-forward-outline"></ion-icon>
                                </div>
                                Enviar
                            </div>
                        </a>
                        <a href="/exchange" class="action-button">
                            <div class="in">
                                <div class="iconbox">
                                    <ion-icon name="swap-vertical"></ion-icon>
                                </div>
                                Intercambio
                            </div>
                        </a>
                        <a href="/my-cards" class="action-button">
                            <div class="in">
                                <div class="iconbox">
                                    <ion-icon name="card-outline"></ion-icon>
                                </div>
                                Mis TC
                            </div>
                        </a>
                    </div>
                    <!-- * action group -->

                    <!-- menu -->
                    <div class="listview-title mt-1">Menu</div>
                    <ul class="listview flush transparent no-line image-listview">
                        <li>
                            <a href="index.html" class="item">
                                <div class="icon-box bg-primary">
                                    <ion-icon name="pie-chart-outline"></ion-icon>
                                </div>
                                <div class="in">
                                    Vision General
                                    <span class="badge badge-primary">10</span>
                                </div>
                            </a>
                        </li>
                        <li>
                            <a href="/pages" class="item">
                                <div class="icon-box bg-primary">
                                    <ion-icon name="document-text-outline"></ion-icon>
                                </div>
                                <div class="in">
                                    Paginas
                                </div>
                            </a>
                        </li>
                        <!-- <li>
                            <a href="/components" class="item">
                                <div class="icon-box bg-primary">
                                    <ion-icon name="apps-outline"></ion-icon>
                                </div>
                                <div class="in">
                                    Componentes
                                </div>
                            </a>
                        </li> -->
                        <li>
                            <a href="/my-cards" class="item">
                                <div class="icon-box bg-primary">
                                    <ion-icon name="card-outline"></ion-icon>
                                </div>
                                <div class="in">
                                    Mis tarjetas
                                </div>
                            </a>
                        </li>
                    </ul>
                    <!-- * menu -->

                    <!-- others -->
                    <div class="listview-title mt-1">Otros</div>
                    <ul class="listview flush transparent no-line image-listview">
                        <li>
                            <a href="/me/settings" class="item">
                                <div class="icon-box bg-primary">
                                    <ion-icon name="settings-outline"></ion-icon>
                                </div>
                                <div class="in">
                                    Ajustes
                                </div>
                            </a>
                        </li>
                        <li>
                            <a href="/support" class="item">
                                <div class="icon-box bg-primary">
                                    <ion-icon name="chatbubble-outline"></ion-icon>
                                </div>
                                <div class="in">
                                    Support
                                </div>
                            </a>
                        </li>
                        <li>
                            <a href="#" class="item">
                                <div class="icon-box bg-primary">
                                    <ion-icon name="log-out-outline"></ion-icon>
                                </div>
                                <div class="in">
                                    Cerrar sesion
                                </div>
                            </a>
                        </li>


                    </ul>
                    <!-- * others -->

                    <!-- send money -->
                    <!-- <div class="listview-title mt-1">Enviar dinero</div>
                    <ul class="listview image-listview flush transparent no-line">
                        <li>
                            <a href="#" class="item">
                                <img src="@/assets/img/sample/avatar/avatar2.jpg" alt="image" class="image">
                                <div class="in">
                                    <div>Artem Sazonov</div>
                                </div>
                            </a>
                        </li>
                        <li>
                            <a href="#" class="item">
                                <img src="@/assets/img/sample/avatar/avatar4.jpg" alt="image" class="image">
                                <div class="in">
                                    <div>Sophie Asveld</div>
                                </div>
                            </a>
                        </li>
                        <li>
                            <a href="#" class="item">
                                <img src="@/assets/img/sample/avatar/avatar3.jpg" alt="image" class="image">
                                <div class="in">
                                    <div>Kobus van de Vegte</div>
                                </div>
                            </a>
                        </li>
                    </ul> -->
                    <!-- * send money -->

                </div>
            </div>
        </div>
    </div>
    <!-- * App Sidebar -->
</template>

<script>
import { mapGetters } from 'vuex';
import axios from 'axios'
import bootstrap from 'bootstrap'
export default {
  name: 'AppSidebar',
  computed: {
    ...mapGetters({ 'currentInfo': 'StateUser' }),
  },
  mounted() {
    const Finapp = {
        RTL: {
            enable: false, // Enable or disable RTL Mode
        },
    }
    
    var appSidebar = document.getElementById("sidebarPanel")
    //-----------------------------------------------------------------------
    // RTL (Right to Left)
    if (Finapp.RTL.enable) {
        var pageHTML = document.querySelector("html")
        pageHTML.dir = "rtl"
        document.querySelector("body").classList.add("rtl-mode")
        if (appSidebar != null) {
            appSidebar.classList.remove("panelbox-left")
            appSidebar.classList.add("panelbox-right")
        }
        document.querySelectorAll(".carousel-full, .carousel-single, .carousel-multiple, .carousel-small, .carousel-slider").forEach(function (el) {
            el.setAttribute('data-splide', '{"direction":"rtl"}')
        })
    }
    //-----------------------------------------------------------------------
    const modal = document.getElementById("sidebarPanel");
    
    modal.addEventListener('hide.bs.modal', () => {
      console.log('hide')  
    })
    modal.addEventListener('hidden.bs.modal', () => {
      console.log('hidden')
    })
    modal.addEventListener('hidePrevented.bs.modal', () => {
      console.log('hidePrevented')  
    })
    modal.addEventListener('shown.bs.modal', () => {
        
    })
  },
  methods: {
    logOut() {
        const modal = document.getElementById("sidebarPanel");
        let self = this
        console.log('logOut')
        axios.post('logout')
        .then(response => {
            console.log('response', response)
            if (response.status == 200) {
                // modal.close()
                // self.$router.push('/login')
                location.replace('/')
            }
        })
        .catch(e => console.log(e))
    },
    closeSidebar() {
        const modal = document.getElementById("sidebarPanel");
    },
  },
}
</script>
