<template>
    <router-link :to="{ name: 'crypto-transaction-detail', params: { tx_hash:tx.hash } }" class="item">
        <div class="icon-box bg-secondary">
        <ion-icon :name="coinIcon(tx)"></ion-icon>
        </div>
        <div class="in">
            <div>
                <strong>{{ functionText(tx) }}</strong>
                <div class="text-small text-secondary">functionFragment: {{ tx.functionFragment.name }}</div>
                <!-- <div class="text-small text-secondary">From: {{ tx.from }}</div>
                <div class="text-small text-secondary">To (Token): {{ coinName(tx.to) }}</div>
                <div class="text-small text-secondary">src: {{ tx.src }}</div>
                <div class="text-small text-secondary">dst: {{ tx.dst }}</div> -->
                <!-- <div class="text-small text-secondary">wad: {{ tx.wad }}</div> -->
                <div class="text-small text-secondary">amount: {{ tx.amount }}</div>
            </div>
            <div class="text-end">
                <strong>{{ (parseFloat(tx.amount)/(10 ** coinDecimals(tx.to))).toFixed(coinDecimalsShow(tx.to)).toString() }} {{ coinSymbol(tx.to) }}</strong>
                <!-- <div class="text-small">
                value: {{ tx.value }}
                </div> -->
                <div class="text-small">
                wad: {{ tx.wad }}
                </div>
                <!-- <div class="text-small">
                amount: {{ tx.amount }}
                </div> -->
            </div>
        </div>
    </router-link>
</template>

<script>
import router from '@/router';
import { mapGetters } from 'vuex';
export default {
    props: [
        'tx',
    ],
    computed: {
        ...mapGetters({ 'currentInfo': 'StateUser' }),
        assets() {
        let records = {}
        this.$root.assets.forEach(asset => {
            records[asset.address] = asset
            // records.push(asset.address, address)
        });
        return records
        },
    },
    methods: {
        functionText(tx) {
            let fragmentName = tx.functionFragment.name
            console.log('fragmentName', fragmentName)
            let str = "Ups "
            if (fragmentName == 'transfer' && tx.src == this.currentInfo.wallet.address) str = 'Enviaste '
            if (fragmentName == 'transfer' && tx.dst == this.currentInfo.wallet.address) str = 'Te enviaron '
            if (fragmentName == 'depositExternal') str = 'Deposito de ' + tx.src
            if (fragmentName == 'swap') str = 'Swap ' 
            // str += (tx.wad)
            // str += (parseFloat(tx.amount)/(10 ** this.coinDecimals(tx.to))).toFixed(this.coinDecimalsShow(tx.to)).toString()
            str += ' ' + this.coinSymbol(tx.to)
            return str
        },
        coinName(address) {
            return this.assets[address].coin.name ?? 'xXx'
        },
        coinIcon(tx) {
            let fragmentName = tx.functionFragment.name
            if (fragmentName == 'transfer' && tx.src == this.currentInfo.wallet.address) return 'arrow-forward-outline'
            if (fragmentName == 'transfer' && tx.dst == this.currentInfo.wallet.address) return 'arrow-back-outline'
            else if (fragmentName == 'depositExternal') return 'arrow-down-outline'
            else if (fragmentName == 'withdrawExternal') return 'arrow-up-outline'
            return 'home'
        },
        coinSymbol(address) {
            return this.assets[address].coin.symbol ?? '¿¿??'
        },
        coinDecimals(address) {
            return this.assets[address].coin.decimals ?? 18
        },
        coinDecimalsShow(address) {
            return this.assets[address].coin.decimals_show ?? 8
        },
    },
}
</script>