import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import axios from 'axios';

import "bootstrap/dist/css/bootstrap.min.css"
// import "./assets/js/lib/bootstrap.bundle.min.js"
import "bootstrap" // import "./assets/css/src/bootstrap/bootstrap.min.css"
import "./assets/css/style.css" // import "./assets/css/style.css"
// import "./assets/js/base.js"
import "./assets/js/plugins/splide/splide.min.js"
// import "./assets/js/plugins/apexcharts/apexcharts.min.js"

import AppBottomMenu from "./components/app/BottomMenu.vue"
import AppHeader from "./components/AppHeader.vue"
import AppSidebar from "./components/app/Sidebar.vue"
import AppFooter from "./components/AppFooter.vue"

import FormDeposit from "./components/FormDeposit.vue"
import FormSend from "./components/FormSend.vue"
import FormExchange from "./components/FormExchange.vue"
import FormCalcAction from "./components/FormCalcAction.vue"

import ModalWithdrawActionSheet from "./components/ModalWithdrawActionSheet.vue"
import ModalExchangeActionSheet from "./components/ModalExchangeActionSheet.vue"
import ModalDepositActionSheet from "./components/ModalDepositActionSheet.vue"
import ModalSendActionSheet from "./components/ModalSendActionSheet.vue"

import SectionWalletFull from "./components/SectionWalletFull.vue"
import SectionWalletCard from "./components/SectionWalletCard.vue"

import LoaderBox from "./components/LoaderBox.vue"
import SectionStats from "./components/SectionStats.vue"
import SectionWatchlist from "./components/SectionWatchlist.vue"
import SectionPromo from "./components/SectionPromo.vue"
import SectionLastestNews from "./components/SectionLastestNews.vue"
import SectionMyCards from "./components/SectionMyCards.vue"
import SectionSendMoney from "./components/SectionSendMoney.vue"
import SectionSavingGoals from "./components/SectionSavingGoals.vue"
import SectionMonthlyBills from "./components/SectionMonthlyBills.vue"

import QRCodeGenerator from "./components/QRCodeGenerator.vue"

// Final
import CryptoSectionTransactions from "./components/crypto/SectionTransactions.vue"
import CryptoSectionPortfolio from "./components/crypto/SectionPortfolio.vue"
import CryptoPortfolioListItem from "./components/crypto/PortfolioListItem.vue"
import CryptoTransactionListItem from "./components/crypto/TransactionListItem.vue"

axios.defaults.withCredentials = true
axios.defaults.baseURL = '/api.php';

// axios.interceptors.response.use(function (response) {
//   if (response.headers['x-xsrf-token']) {
//     console.log(response.headers['x-xsrf-token'])
//     document.cookie = 'XSRF-TOKEN=' + response.headers['x-xsrf-token'] + '; path=/';
//   }
//   return response;
// });

console.log('Creando validacion de Router')
await store.dispatch('loadUser')
router.beforeEach(async (to, from, next) => {
  console.log('validando session para router')
  console.log('isAuthenticated', store.getters.isAuthenticated)
  console.log('Start')
  if (to.matched.some(record => record.meta.requiresAuth)) {
    console.log('ReqAuth...')
    if (store.getters.isAuthenticated) {
      console.log('Conectado...')
      next()
    } else {
      console.log('No Conectado...')
      next({
        path: '/auth/login',
        query: { redirect: to.fullPath }
      })
    }
  } 
  else if (to.matched.some(record => record.meta.guest)) {
    if (store.getters.isAuthenticated) {
      console.log('Conectado...')
      next({
        path: '/',
        query: { origin: to.fullPath }
      })
    } else {
      console.log('No Conectado...')
      next()
    }
  }
  else {
    next()
  }
  console.log('End')
  // next()
})

const app = createApp(App)

app.use(store)
app.use(router)

app.component('LoaderBox', LoaderBox)
app.component('AppBottomMenu', AppBottomMenu)
app.component('AppHeader', AppHeader)
app.component('AppSidebar', AppSidebar)
app.component('AppFooter', AppFooter)

app.component('ModalWithdrawActionSheet', ModalWithdrawActionSheet)
app.component('ModalExchangeActionSheet', ModalExchangeActionSheet)
app.component('ModalDepositActionSheet', ModalDepositActionSheet)
app.component('ModalSendActionSheet', ModalSendActionSheet)

app.component('FormDeposit', FormDeposit)
app.component('FormSend', FormSend)
app.component('FormExchange', FormExchange)

app.component('SectionWalletFull', SectionWalletFull)
app.component('SectionWalletCard', SectionWalletCard)

app.component('SectionStats', SectionStats)
app.component('SectionWatchlist', SectionWatchlist)
app.component('SectionPromo', SectionPromo)
app.component('SectionLastestNews', SectionLastestNews)

app.component('SectionMyCards', SectionMyCards)
app.component('SectionSendMoney', SectionSendMoney)
app.component('SectionMonthlyBills', SectionMonthlyBills)
app.component('SectionSavingGoals', SectionSavingGoals)

app.component('FormCalcAction', FormCalcAction)
app.component('QRCodeGenerator', QRCodeGenerator)


// Final
app.component('crypto-section-transactions', CryptoSectionTransactions)
app.component('crypto-section-portfolio', CryptoSectionPortfolio)
app.component('crypto-portfolio-list-item', CryptoPortfolioListItem)
app.component('crypto-transaction-list-item', CryptoTransactionListItem)


app.mount("#app")