<template>
    <div class="section mt-4">
        <div class="section-heading">
            <h2 class="title">Activos Digitales</h2>
            <a href="/crypto/portfolio" class="link">Ver todo</a>
        </div>
        <div class="card">
            <ul class="listview flush transparent no-line image-listview detailed-list mt-1 mb-1">
                
            <li v-if="loading">
              <a href="#" class="item">
                <div class="icon-box spinner-border text-primary" role="status"></div>
                <div class="in">
                  <div>
                    <strong>Cargando...</strong>
                    <div class="text-small text-secondary"></div>
                  </div>
                  <div class="text-end">
                    <strong></strong>
                    <div class="text-small"></div>
                  </div>
                </div>
              </a>
            </li>

                <li v-for="(record, i) in records">
                    <crypto-portfolio-list-item :key="'fast-asset-' + record.id" v-bind:item="record" />
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    name: 'SectionPortfolio',
    data() {
        return {
        };
    },
    computed: {
        records() {
            return this.$root.assets;
        },
        loading() {
            return this.$root.assets.length == 0
        },
    },
    mounted() {
      this.$root.getCryptoAssets()
    }
}
</script>