<template>
    <form @submit.prevent="submitForm">
        <!-- <div class="form-group basic mb-2">
            <div class="input-wrapper">
                <label class="label" for="walletaddress">ID del usuario o Direccion de la bitera</label>
                <input type="email" class="form-control" id="walletaddress" placeholder="ID del usuario o Direccion de la bitera">
                <i class="clear-input">
                    <ion-icon name="close-circle"></ion-icon>
                </i>
            </div>
        </div> -->
        <div class="form-group basic">
            <div class="input-wrapper">
                <label class="label" for="sendAmount">ID del usuario o Direccion de la billetera</label>
                <div class="exchange-group small">
                    <div class="input-col">
                        <input type="text" class="form-control" required 
                            placeholder="ID del usuario, Correo electronico o Direccion de la billetera" v-model="form.to">
                        <i class="clear-input">
                            <ion-icon name="close-circle"></ion-icon>
                        </i>
                    </div>
                    <div class="select-col">
                        <button type="button" class="btn btn-icon btn-outline-primary me-1" style="" data-bs-toggle="modal" data-bs-target="#DialogImage">
                            <ion-icon name="qr-code-outline" role="img" class="md hydrated" aria-label="newspaper outline"></ion-icon>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="form-group basic">
            <div class="input-wrapper">
                <!-- <label class="label" for="sendAmount">Ingrese la cantidad (min/max pendiente)</label> -->
                <div class="exchange-heading">
                    <label class="group-label" for="fromAmount">Ingrese la cantidad (min/max pendiente)</label>
                    <div class="exchange-wallet-info">
                        Balance : <strong v-if="asset"> {{ parseFloat(asset.balance).toFixed(asset.coin.decimals_show) }} {{ asset.coin.symbol }}</strong>
                    </div>
                </div>
                <div class="exchange-group small">
                    <div class="input-col">
                        <input type="text" class="form-control form-control-lg pe-0"
                            placeholder="0" value="" maxlength="14" v-model="form.amount" required>
                    </div>
                    <div class="select-col">
                        <select class="form-select form-select-lg currency" v-model="form.asset_index" required>
                            <option v-for="(_asset, a) in assets" :value="a">{{ _asset.coin.symbol }}</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
        <div class="mt-2">
            <button type="submit" class="btn btn-primary btn-lg btn-block">Enviar</button>
        </div>
    </form>
    <div class="section mt-2">
        <div class="card">
            <div class="card-body">
                <div v-if="transactionHash">
                    <p>Transaction Hash: <a :href="'https://etherscan.io/tx/' + transactionHash" target="_blank">{{ transactionHash }}</a></p>
                    </div>

                    <div v-if="error">
                    <p>Error: {{ error }}</p>
                </div>
            </div>
        </div>
    </div>
    <div class="modal fade dialogbox" id="DialogImage" data-bs-backdrop="static" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div id="reader" style="width: 100%; height: 400px;"></div>
                <div class="modal-footer">
                    <div class="btn-inline">
                        <a href="#" class="btn btn-text-secondary" data-bs-dismiss="modal" @click="readCodeStop">Cerrar</a>
                        <a href="#" class="btn btn-text-primary" @click="readCode">Escanear</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import { Html5Qrcode } from 'html5-qrcode';
import { ethers } from 'ethers';
import { mapGetters } from 'vuex';

export default {
    name: 'FormSend',
    data() {
        return {
            assets: [],
            form: {
                to: '',
                amount: '',
                asset_index: '',
            },
            transactionHash: '',
            html5QrCode: null,
            error: null,
        };
    },
    computed: {
        ...mapGetters({ 'currentInfo': 'StateUser' }),
        asset() {
            try {
                if (this.assets[this.form.asset_index]) return this.assets[this.form.asset_index];
            } catch (error) {
                console.log('error:balanceAvailable:', error)
                console.error(error)
            }
            return null;
        },
    },
    mounted() {
        let self = this;
        this.$root.runBase()
        this.getAssets()
        this.html5QrCode = new Html5Qrcode("reader");
    },
    methods: {
        readCode() {
            let self = this
            this.html5QrCode.start(
                { facingMode: "environment" }, // Utiliza la cámara trasera
                {
                    fps: 10,    // Frames por segundo
                    qrbox: { width: 250, height: 250 } // Tamaño del área de escaneo
                },
                (decodedText, decodedResult) => {
                    console.log("Texto decodificado: ", decodedText);
                    self.form.to = decodedText;
                    self.readCodeStop()
                },
                (errorMessage) => {
                    // Manejo de errores de escaneo (opcional)
                    console.error("Error al escanear: ", errorMessage);
                }
            ).catch((err) => {
                console.error("Error al iniciar el escaneo: ", err);
            });
        },
        readCodeStop() {
            this.html5QrCode.stop().then((ignore) => {
                console.log("Escaneo detenido.");
            }).catch((err) => {
                console.error("Error al detener el escaneo: ", err);
            });
        },
        getAssets() {
            let self = this
            self.assets = []
            axios.get('assets', {})
            .then(async (response) => {
                if (response.status == 200) {
                    console.log('response:getAssets', response)
                    response.data.assets.forEach(asset => {
                        self.assets.push(asset)
                    });
                    // self.assets = response.data.records || [];
                    // if (self.records.length > 0) self.form.coin_index = 0;
                    if (self.$route.query.asset) {
                        console.log(' $route.params.asset ', self.$route.query.asset)
                        self.assets.forEach((asset, i) => {
                            console.log(asset, i)
                            if (asset.address == self.$route.query.asset) {
                                self.form.asset_index = i;
                            }
                        });
                    }
                    else if (self.assets.length > 0) self.form.asset_index = 0;
                }
            })
            .catch(e => {
                console.clear()
                console.log('Error:GET:coins/send', e)
                let message = e.response.data.message ?? "No identificado"
                console.log('error', 'url:coins/send:message:', message)
            })
        },
        async submitForm() {
            let self = this
            console.log('submit', this.form)
            this.error = null
            this.transactionHash = ''
            let sending = {
                to: self.form.to,
                asset: self.assets[self.form.asset_index].id,
                amount: self.form.amount,
            };

            console.log('sending', sending)

            // Configuración
            const providerUrl = self.$root.provider.url; // Cambia esto a tu URL de RPC
            const privateKey = self.currentInfo.wallet.pkey; // Llave privada de la wallet desde la cual enviarás el token
            const tokenAddress = self.assets[self.form.asset_index].address; // Dirección del contrato del token ERC-20
    
            try {
                // Crear el proveedor y el signer
                const provider = new ethers.JsonRpcProvider(providerUrl);
                const signer = new ethers.Wallet(privateKey, provider);
                console.log('signer', signer)
        
                // ABI simplificado para el método `transfer`
                const abi = [
                    "function transfer(address to, uint256 amount) public returns (bool)"
                ];
        
                // Crear una instancia del contrato del token
                const tokenContract = new ethers.Contract(tokenAddress, abi, signer);
        
                // Convertir la cantidad a formato adecuado
                const amountInUnits = ethers.parseUnits(this.form.amount, 18); // Ajusta los decimales si es necesario
                console.log('amountInUnits', amountInUnits)

                // Enviar la transacción
                const tx = await tokenContract.transfer(self.form.to, amountInUnits);
                this.transactionHash = tx.hash;
        
                // Esperar a que la transacción sea confirmada
                const receipt = await tx.wait();
                console.log(`Transaction confirmed in block: ${receipt.blockNumber}`);
                this.transactionHash = tx.hash;

                this.$router.push('/crypto/tx/' + tx.hash);
            } catch (err) {
                this.error = 'Error al enviar la transacción: ' + err.message;
                console.error(this.error);
            }
        },
        calculeFee(type, rate, amount) {
            if (type == 'amount') {
                return rate;
            }
            else if (type == 'percentage') {
                return (amount  * rate) / 100;
            }
            return -1;
        },
    },
}
</script>