<template>

    <form @submit.prevent="submitForm">
        <div class="form-group basic">
            <div class="input-wrapper">
                <label class="label" for="depositAmount">Ingrese la cantidad</label>
                <div class="exchange-group small">
                    <div class="input-col">
                        <input type="text" class="form-control form-control-lg pe-0" id="depositAmount" placeholder="0" maxlength="14" v-model="form.amount">
                    </div>
                    <div class="select-col">
                        <select class="form-select form-select-lg currency" v-model="form.coin_index" @change="clearGateway">
                            <option v-for="(coin, c) in coins" :key="'coin-selet-' + coin.id" :value="c">{{ coin.symbol }}</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>

        <!-- <div class="form-group basic mb-2">
            <div class="input-wrapper">
                <label class="label" for="account2">Pasarela o Proveedor</label>
                <select class="form-control custom-select" v-model="form.gateway_index">
                    <option v-for="(gateway, g) in gateways" :value="g">Recarga desde {{ gateway.provider.name }} x {{ gateway.asset.coin.symbol }} ({{ gateway.fee }}{{ gateway.fee_type }}) [{{ gateway.asset.type }}]</option>
                </select>
            </div>
        </div> -->

        <div class="form-group basic">
            <div class="input-wrapper">
                <label class="label" for="depositAmount">Cantidad a recibir</label>
                <div class="exchange-group small">
                    <div class="input-col">
                        <input type="text" class="form-control form-control-lg pe-0" placeholder="0" maxlength="14" v-bind:value="coinResult" >
                    </div>
                    <div class="select-col">
                        <select class="form-select form-select-lg currency" v-model="form.gateway_index">
                            <option v-for="(gateway, g) in gateways" :value="g">{{ gateway.asset.coin.symbol }}</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>

        <div class="form-group basic mb-2">
            <div class="input-wrapper">
                <label class="label" for="account2">Pasarela o Proveedor</label>
                <select class="form-control custom-select" v-model="form.gateway_index">
                    <option v-for="(gateway, g) in gateways" :value="g">Recarga desde {{ gateway.provider.name }} x {{ gateway.asset.coin.symbol }}</option>
                </select>
            </div>
        </div>
        
        <div class="table-responsive">
            <table class="table table-striped">
                <!-- <thead>
                    <tr>
                        <th scope="col">--</th>
                        <th scope="col" class="text-end">Balance</th>
                    </tr>
                </thead> -->
                <tbody v-if="gateway !== null && coin !== null">
                    <tr>
                        <th scope="row">{{ coin.name }}</th>
                        <td class="text-end">{{ parseFloat(form.amount).toFixed(coin.decimals_show) }} {{ coin.symbol }}</td>
                    </tr>
                    <tr>
                        <th scope="row">x {{ gateway.asset.coin.name }}</th>
                        <td class="text-end">{{ parseFloat(coinResult).toFixed(gateway.asset.coin.decimals_show) }} {{ gateway.asset.coin.symbol }}</td>
                    </tr>
                    <tr>
                        <th scope="row">{{ gateway.provider.name }}</th>
                        <td class="text-end">
                            {{ parseFloat(gateway.fee).toFixed(gateway.decimals_show) }} {{ gateway.fee_type == 'percentage' ? '%' : coin.symbol }}
                        </td>
                    </tr>
                    <tr>
                        <th scope="row">{{ gateway.provider.name }}</th>
                        <td class="text-end">
                            {{ parseFloat(fee).toFixed(gateway.decimals_show) }} {{ coin.symbol }} (Fee)
                        </td>
                    </tr>
                    <tr>
                        <th scope="row">Tipo</th>
                        <td class="text-end">[{{ gateway.asset.type }}]</td>
                    </tr>
                    <tr>
                        <th scope="row">Total a pagar</th>
                        <td class="text-end">{{ parseFloat(coinResult + fee).toFixed(gateway.decimals_show) }} {{ coin.symbol }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        
        <div class="form-button-group transparent">
            <button type="submit" class="btn btn-primary btn-block btn-lg">
                Continuar
            </button>
        </div>
    </form>
    

</template>

<script>
import axios from 'axios';

export default {
    name: 'FormSend',
    // props: ['type'],
    data() {
        return {
            type: 'deposit',
            coins: [],
            form: {
                amount: '380',
                coin_index: '',
                gateway_index: '',
            },
        };
    },
    computed: {
        coinResult() {
            try {
                return this.form.amount / this.gateway.rate;
            } catch (error) {
                console.log('error:coinResult:', error)
                console.error(error)
            }
            return 0;
        },
        coin() {
            try {
                if (this.coins[this.form.coin_index]) {
                    return this.coins[this.form.coin_index];
                }
            } catch (error) {
                console.log('error:coin:', error)
                console.error(error)
            }
            return null;
        },
        gateway() {
            try {
                if (this.gateways[this.form.gateway_index]) {
                    return this.gateways[this.form.gateway_index];
                }
            } catch (error) {
                console.log('error:gateway:', error)
                console.error()
            }
            return null;
        },
        fee() {
            try {
                if (this.gateway && this.gateway.fee_type && this.gateway.fee && this.form.amount)
                // return calculeFee(this.gateway.fee_type, this.gateway.fee, this.form.amount);
                if (this.gateway.fee_type === 'amount') {
                    return this.gateway.fee;
                } else if (this.gateway.fee_type === 'percentage') {
                    return (this.form.amount * this.gateway.fee) / 100;
                }
                return -1;
            } catch (error) {
                console.log('error:fee:', error)
                console.error()
            }
            return 99999999999999999999999999999;
        },
        gateways() {
            try {
                if (this.coins[this.form.coin_index] && this.coins[this.form.coin_index].gateways_in)
                    return this.coins[this.form.coin_index].gateways_in
            } catch (error) {
                console.log('error:gateways:', error)
                console.error()
            }
            return [];
        },
    },
    mounted() {
        let self = this
        this.$root.runBase();
        this.getCoins()
    },
    methods: {
        clearGateway() {
            this.form.gateway_index = 0
        },
        getCoins() {
            let self = this
            this.coins = []
            axios.get(`records/coins`, {
                params: {
                    join: [
                        'gateways_in,providers',
                        'gateways_in,assets,coins',
                    ],
                },
            })
            .then(response => {
                if (response.status === 200) {
                    response.data.records.forEach(coin => {
                        if (coin.gateways_in && coin.gateways_in.length > 0) self.coins.push(coin)
                    });
                    if (self.coins.length > 0) self.form.coin_index = 0, self.clearGateway()
                }
            })
            .catch(e => {
                console.clear();
                console.log('Error:GET:coins/send', e);
                let message = e.response?.data?.message ?? "No identificado";
                console.log('error', 'url:coins/send:message:', message);
            });
        },
        submitForm() {
            // Verifica si los datos están disponibles antes de proceder
            if (
                this.pair.record &&
                this.pair.record.from &&
                this.pair.record.to &&
                this.gatewayInfo.record
            ) {
                let tx = {
                    type: this.type,
                    coin_from: this.pair.record.from.id,
                    coin_to: this.pair.record.to.id,
                    amount: this.form.amount,
                    gateway_id: this.gatewayInfo.record.id,
                    pay_tx: this.form.gateway_index.split(":")[0] ?? 'NAN',
                };

                console.log(tx);
                this.form.user_verify= true
            } else {
                console.error('Error: No se puede obtener los datos necesarios para la transacción.');
                // Puedes agregar aquí un mensaje de error para el usuario si lo deseas
            }
        },
        calculeFee(type, fee, amount) {
            if (type === 'amount') {
                return fee;
            } else if (type === 'percentage') {
                return (amount * fee) / 100;
            }
            return -1;
        },
    },
};
</script>
