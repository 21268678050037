<template>

    <!-- App Header -->
    <div class="appHeader">
        <div class="left">
            <a href="/" class="headerButton goBack">
                <ion-icon name="chevron-back-outline"></ion-icon>
            </a>
        </div>
        <div class="pageTitle">
            Detalle de la transacción
        </div>
        <div class="right">
            <a href="#" class="headerButton" data-bs-toggle="modal" data-bs-target="#DialogBasic">
                <ion-icon name="trash-outline"></ion-icon>
            </a>
            <a href="#" class="headerButton" data-bs-toggle="modal" data-bs-target="#DialogBasic">
                <ion-icon name="alert"></ion-icon>
            </a>
        </div>
    </div>
    <!-- * App Header -->

    <!-- Dialog Basic -->
    <div class="modal fade dialogbox" id="DialogBasic" data-bs-backdrop="static" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Eliminar / Ocultar</h5>
                </div>
                <div class="modal-body">
                    Esto solo se elimina en tu cuenta, Estas seguro?
                </div>
                <div class="modal-footer">
                    <div class="btn-inline">
                        <a href="#" class="btn btn-text-secondary" data-bs-dismiss="modal">CANCELAR</a>
                        <a href="#" class="btn btn-text-primary" data-bs-dismiss="modal">ELIMINAR</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- * Dialog Basic -->

    <!-- App Capsule -->
    <div id="appCapsule" class="full-height">

        <div class="section mt-2 mb-2" v-if="record !== null && assets !== null">
            <div class="listed-detail mt-3">
                <div class="icon-wrapper">
                    <div class="iconbox">
                        <ion-icon name="arrow-forward-outline"></ion-icon>
                    </div>
                </div>
                <h3 class="text-center mt-2">{{ functionText(record) }}</h3>
            </div>
            <ul class="listview flush transparent simple-listview no-space mt-3">
                <!-- <li>
                    <strong>amount</strong>
                    <span class="text-success">{{ record.amount }}</span>
                </li> -->
                <li>
                    <strong>blockNumber</strong>
                    <span>{{ record.blockNumber }}</span>
                </li>
                <!-- <li>
                    <strong>chainId</strong>
                    <span>{{ record.chainId }}</span>
                </li> -->
                <li>
                    <strong>To</strong>
                    <span class="text-success">{{ coinName(record.to) }} ({{ coinSymbol(record.to) }})</span>
                </li>
                <li>
                    <strong>Origen</strong>
                    <span>{{ record.src }}</span>
                </li>
                <li>
                    <strong>Para</strong>
                    <span>{{ record.dst }}</span>
                </li>
                <!-- <li>
                    <strong>From</strong>
                    <span>{{ record.from }}</span>
                </li> -->
                <li>
                    <strong>functionSelector</strong>
                    <span>{{ record.functionSelector }}</span>
                </li>
                <li>
                    <strong>gasLimit</strong>
                    <span>{{ record.gasLimit }}</span>
                </li>
                <li>
                    <strong>gasPrice</strong>
                    <span>{{ record.gasPrice }}</span>
                </li>
                <li>
                    <strong>hash</strong>
                    <span>{{ record.hash }}</span>
                </li>
                <li>
                    <strong>index</strong>
                    <span>{{ record.index }}</span>
                </li>
                <!-- <li>
                    <strong>maxFeePerBlobGas</strong>
                    <span>{{ record.maxFeePerBlobGas }}</span>
                </li>
                <li>
                    <strong>maxFeePerGas</strong>
                    <span>{{ record.maxFeePerGas }}</span>
                </li>
                <li>
                    <strong>maxPriorityFeePerGas</strong>
                    <span>{{ record.maxPriorityFeePerGas }}</span>
                </li> -->
                <li>
                    <strong>nonce</strong>
                    <span>{{ record.nonce }}</span>
                </li>
                <li>
                    <strong>type</strong>
                    <span>{{ record.type }}</span>
                </li>
                <!-- <li>
                    <strong>value</strong>
                    <span>{{ record.value }}</span>
                </li> -->
                <li>
                    <strong>Wad</strong>
                    <h3 class="m-0">{{ (parseFloat(record.amount)/(10 ** coinDecimals(record.to))).toFixed(coinDecimalsShow(record.to)).toString() }} {{ coinSymbol(record.to) }}</h3>
                </li>
                <!-- <li>
                    <strong>Comprobado</strong>
                    <span>SI</span>
                </li>
                <li>
                    <strong>Fecha</strong>
                    <span>Sep 25, 2024 10:45 AM</span>
                </li>
                <li>
                    <strong>Cantidad</strong>
                    <h3 class="m-0">{{ record.amount }}</h3>
                </li> -->
            </ul>
        </div>
    </div>
    <!-- * App Capsule -->
</template>

<script>
import { mapGetters } from 'vuex'
import { ethers } from 'ethers'

export default {
    data() {
        return {
            record: null,
        };
    },
    computed: {
        ...mapGetters({ 'currentInfo': 'StateUser' }),
        assets() {
            let records = {}
            this.$root.assets.forEach(asset => {
                records[asset.address] = asset
                // records.push(asset.address, address)
            });
            return (JSON.stringify(records).length > 2) ? records : null
        },
    },
    async mounted() {
        // this.getTransaction
        if (this.$route.params.tx_hash) {
            let txHash = this.$route.params.tx_hash
            console.log('txHash', txHash)
            const provider = new ethers.JsonRpcProvider(this.$root.provider.url);
            let foundTransactions = [];
            const erc20Abi = [
                "function transfer(address dst, uint wad)",
                "function transferFrom(address src, address dst, uint wad)",
                "function withdrawExternal(address gw, address dst, uint wad)",
                "function withdrawTo(uint wad, address dst)",
                "function withdraw(uint wad)",
                "function depositExternal(address gw, address dst, uint wad)",
                "function depositTo(address dst)",
                "function deposit()",
            ];
            // Crear una instancia de la interfaz usando el ABI
            let walletAddress = this.currentInfo.wallet.address ?? this.currentInfo.wallet
            const iface = new ethers.Interface(erc20Abi);

            let itemTx = {}
            let src = null
            let dst = null
            let wad = 0
            let amount = 0
            let tx = await provider.getTransaction(txHash)
            console.log('txHash', txHash)
            tx.functionSelector = tx.data.slice(0, 10); // El 0x + 8 caracteres = 10 caracteres
            tx.functionFragment = iface.getFunction(tx.functionSelector);
            if (tx.functionSelector == '0x' || tx.functionFragment == null) {} // Moneda/Funciones nativas
            else {
                tx.decodedData = iface.decodeFunctionData(tx.functionFragment.name, tx.data);
                console.log(`Función llamada: ${tx.functionFragment.name}`);
                if (tx.functionFragment.name === "transfer") {
                    src = tx.from, dst = tx.decodedData.dst, wad = tx.decodedData.wad, amount = tx.decodedData.wad
                }
                else if (tx.functionFragment.name === "transferFrom") {
                    src = tx.decodedData.src, dst = tx.decodedData.dst, wad = tx.decodedData.wad, amount = tx.decodedData.wad
                }
                else if (tx.functionFragment.name === "withdrawExternal" || tx.functionFragment.name === "depositExternal") {
                    // tx.from = tx.decodedData.dst, 
                    tx.to = tx.decodedData.asset, src = tx.decodedData.asset, dst = tx.decodedData.dst, wad = tx.decodedData.wad, amount = tx.decodedData.wad
                    console.log('withdrawExternal:depositExternal', tx, tx.decodedData.dst, tx.decodedData.asset, tx.decodedData.wad)
                }
                else if (tx.functionFragment.name === "withdrawTo") {
                    src = tx.from, dst = tx.decodedData.dst, wad = tx.decodedData.wad, amount = tx.decodedData.wad
                }
                else if (tx.functionFragment.name === "depositTo") {
                    src = tx.from, dst = tx.decodedData.dst, wad = tx.value, amount = tx.value
                }
                if ((src && src.toLowerCase() === walletAddress.toLowerCase()) || (dst && dst.toLowerCase() === walletAddress.toLowerCase())) { // PARA TOKENS
                    tx.src = src, tx.dst = dst, tx.wad = wad, tx.amount = amount
                    foundTransactions.push(tx);
                }
            }
            this.record = tx
            console.log('tx', tx)
        }
    },
    methods: {
        functionText(tx) {
            let fragmentName = tx.functionFragment.name
            // coinName(tx.to) + tx.functionFragment.name;
            let str = "Ups "
            if (fragmentName == 'transfer' && tx.src == this.currentInfo.wallet.address) str = 'Enviaste '
            if (fragmentName == 'transfer' && tx.dst == this.currentInfo.wallet.address) str = 'Te enviaron '
            if (fragmentName == 'depositExternal') str = 'Deposito de ' + tx.src
            // str += (tx.wad)
            // str += (parseFloat(tx.amount)/(10 ** this.coinDecimals(tx.to))).toFixed(this.coinDecimalsShow(tx.to)).toString()
            str += ' ' + this.coinSymbol(tx.to)
            return str
        },
        coinName(address) {
        return this.assets[address].coin.name ?? 'xXx'
        },
        coinIcon(tx) {
        let fragmentName = tx.functionFragment.name
        if (fragmentName == 'transfer' && tx.src == this.currentInfo.wallet.address) return 'arrow-forward-outline'
        if (fragmentName == 'transfer' && tx.dst == this.currentInfo.wallet.address) return 'arrow-back-outline'
        else if (fragmentName == 'depositExternal') return 'arrow-down-outline'
        else if (fragmentName == 'withdrawExternal') return 'arrow-up-outline'
        return 'home'
        },
        coinSymbol(address) {
        return this.assets[address].coin.symbol ?? '¿¿??'
        },
        coinDecimals(address) {
        return this.assets[address].coin.decimals ?? 18
        },
        coinDecimalsShow(address) {
        return this.assets[address].coin.decimals_show ?? 8
        },
        // async getTransaction() {
        //     let self = this
        //     self.records = []
        //     console.log('getTransaction')
        //     try {
        //         let walletAddress = self.currentInfo.wallet.address ?? self.currentInfo.wallet
        //         console.log('walletAddress', walletAddress)
        //         const provider = new ethers.JsonRpcProvider(self.$root.provider.url);
        //         // Obtener el último bloque
        //         // const latestBlockNumber = (self.latestBlockNumber >= 0) ? self.latestBlockNumber : await provider.getBlockNumber();
        //         const latestBlockNumber = await provider.getBlockNumber();
        //         console.log('latestBlockNumber', latestBlockNumber)
        //         // Definir cuántos bloques atrás deseas explorar (en este caso, los últimos 10 bloques)
        //         const numberOfBlocksToCheck = 10;
        //         let foundTransactions = [];
        //         // Recorrer los bloques recientes
        //         for (let i = latestBlockNumber; i >= latestBlockNumber - numberOfBlocksToCheck; i--) {
        //             self.latestBlockNumber--;
        //         const block = await provider.getBlock(i);
        //         // console.log("Block:", block);
        //         // console.log("Transactions:", block.transactions);
        //         // ABI mínima para un token ERC-20 (solo necesita las firmas de las funciones)
        //         const erc20Abi = [
        //             "function transfer(address dst, uint wad)",
        //             "function transferFrom(address src, address dst, uint wad)",
        //             "function withdrawExternal(address gw, address dst, uint wad)",
        //             "function withdrawTo(uint wad, address dst)",
        //             "function withdraw(uint wad)",
        //             "function depositExternal(address gw, address dst, uint wad)",
        //             "function depositTo(address dst)",
        //             "function deposit()",
        //         ];
        //         // Crear una instancia de la interfaz usando el ABI
        //         const iface = new ethers.Interface(erc20Abi);
        //         block.transactions.forEach(async (txHash) => {
        //             let itemTx = {}
        //             let src = null
        //             let dst = null
        //             let wad = 0
        //             let amount = 0
        //             let tx = await provider.getTransaction(txHash)
        //             console.log('txHash', txHash, 'tx', tx)

        //             // src = tx.from
                    
        //             tx.functionSelector = tx.data.slice(0, 10); // El 0x + 8 caracteres = 10 caracteres
        //             tx.functionFragment = iface.getFunction(tx.functionSelector);
        //             if (tx.functionSelector == '0x' || tx.functionFragment == null) {} // Moneda/Funciones nativas
        //             else {
        //             tx.decodedData = iface.decodeFunctionData(tx.functionFragment.name, tx.data);
        //             console.log(`Función llamada: ${tx.functionFragment.name}`);
        //             if (tx.functionFragment.name === "transfer") {
        //                 src = tx.from, dst = tx.decodedData.dst, wad = tx.decodedData.wad, amount = tx.decodedData.wad
        //             }
        //             else if (tx.functionFragment.name === "transferFrom") {
        //                 src = tx.decodedData.src, dst = tx.decodedData.dst, wad = tx.decodedData.wad, amount = tx.decodedData.wad
        //             }
        //             else if (tx.functionFragment.name === "withdrawExternal" || tx.functionFragment.name === "depositExternal") {
        //                 src = tx.decodedData.gw, dst = tx.decodedData.dst, wad = tx.decodedData.wad, amount = tx.decodedData.wad
        //             }
        //             else if (tx.functionFragment.name === "withdrawTo") {
        //                 src = tx.from, dst = tx.decodedData.dst, wad = tx.decodedData.wad, amount = tx.decodedData.wad
        //             }
        //             else if (tx.functionFragment.name === "depositTo") {
        //                 src = tx.from, dst = tx.decodedData.dst, wad = tx.value, amount = tx.value
        //             }
        
        //             if ((src && src.toLowerCase() === walletAddress.toLowerCase()) || (dst && dst.toLowerCase() === walletAddress.toLowerCase())) { // PARA TOKENS
        //                 tx.src = src, tx.dst = dst, tx.wad = wad, tx.amount = amount
        //                 foundTransactions.push(tx);
        //             }
                    
        //             }
        //         });

        //         }
        //         self.records = self.records.concat(foundTransactions);
        //         if (this.records.length === 0) {
        //         console.log("No se encontraron transacciones para la dirección proporcionada.");
        //         }
        //     } catch (error) {
        //         console.error("Error al obtener las transacciones:", error);
        //     }
        // }
    }
}
</script>